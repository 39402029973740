import React from "react";
import { Logout } from "./Logout";
import { Link } from "react-router-dom";
import { makeStyles, Button } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    padding: ".5rem",
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    display: "flex"
  },
  tools: {
    flexGrow: 1
  }
}));

export const Nav = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.tools}>
        <Button to="/voyager" component={Link} color="inherit">
          GraphQL Voyager
        </Button>
        <Button to="/graphiql" component={Link} color="inherit">
          GraphiQL
        </Button>
      </div>
      <Logout />
    </div>
  );
};
