import queryString from "query-string";
import { AppTheme } from "./theme";

const hostMap: {
  [key: string]: string;
} = {
  "vodafone.affinity.io": AppTheme.VODAFONE,
  "vodafone.uat.affinity.io": AppTheme.VODAFONE,
  "dwpbeta.digitalwholesalesolutions.com": AppTheme.DWS
};

export function hostToBrand(host: string): string {
  const brand: string = hostMap[host];

  if (brand) {
    return brand;
  }

  const search = queryString.parse(window.location.search);

  if (typeof search.brand === "string") {
    return search.brand.toUpperCase();
  }

  return AppTheme.AFFINITY;
}
