import { createMuiTheme } from "@material-ui/core/styles";
import { affinityColors } from "./styleData";
import { baseThemeOptions, createOverrides } from "../_base";
import favicon from "./favicon.png";

// See https://material-ui.com/customization/default-theme/ for avaliable options
// TODO: Fix the typescript warnings. Theme def errors
const baseAffinityTheme = createMuiTheme({
  ...baseThemeOptions,
  // add Affinity specific theme options here
  palette: {
    common: {
      black: affinityColors.commonBlack.value,
      white: affinityColors.commonWhite.value
    },
    primary: {
      light: affinityColors.primaryLight.value,
      main: affinityColors.primary.value,
      dark: affinityColors.primaryDark.value,
      contrastText: "#fff"
    },
    secondary: {
      light: affinityColors.secondaryLight.value,
      main: affinityColors.secondary.value,
      dark: affinityColors.secondaryDark.value,
      contrastText: "#fff"
    },
    success: {
      light: affinityColors.successLight.value,
      main: affinityColors.success.value,
      dark: affinityColors.successDark.value,
      contrastText: "#fff"
    },
    error: {
      light: affinityColors.errorLight.value,
      main: affinityColors.error.value,
      dark: affinityColors.errorDark.value,
      contrastText: "#fff"
    },
    warning: {
      light: affinityColors.warningLight.value,
      main: affinityColors.warning.value,
      dark: affinityColors.warningDark.value,
      contrastText: "#fff"
    },
    info: {
      light: affinityColors.infoLight.value,
      main: affinityColors.info.value,
      dark: affinityColors.infoDark.value,
      contrastText: "#fff"
    },
    grey: {
      // @ts-ignore
      ...[50, 100, 200, 300, 400, 500, 600, 700, 800, 900].reduce(
        (acc, curr) => ({
          // @ts-ignore
          ...acc,
          [curr]: affinityColors[`grey${curr}`].value
        }),
        50
      )
    },
    text: {
      primary: affinityColors.textPrimary.value,
      secondary: affinityColors.textSecondary.value,
      disabled: affinityColors.textDisabled.value
    },
    background: {
      default: affinityColors.neutralGhost.value
    },
    divider: affinityColors.divider.value,
    action: {
      active: affinityColors.actionActive.value,
      hover: affinityColors.actionHover.value,
      selected: affinityColors.actionSelected.value,
      disabled: affinityColors.actionDisabled.value,
      disabledBackground: affinityColors.actionDisabledBackground.value
    }
  }
} as any);

// JSS styling overrides
const overrides = {
  ...createOverrides(baseAffinityTheme),
  // add Affinity specific overrides here
  MuiCardHeader: {
    // title: {
    //   fontSize: "1rem"
    // },
    // TODO: Decide what the rule is about header color.
    // Applying this mlooks right in some places but wrong in others.
    // Think Sales price book tables vs data cards or CPQ selection areas
    // root: {
    //   backgroundColor: affinityColors.neutralStorm.value,
    //   color: "#fff"
    // }
  }
};

const affinityTheme = createMuiTheme({
  ...baseAffinityTheme,
  overrides
});

const affinityBrand = {
  favicon,
  name: "Affinity",
  pageTitle: "Affinity"
};

export { affinityTheme, affinityColors, affinityBrand };
