import React from "react";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import FullScreenCenter from "../shared/FullScreenCenter";

export interface LoadingFullScreenProps {
  message: string;
  cancel?: () => any;
  variant?: "overlay";
}

export const LoadingFullScreen = ({
  message,
  cancel,
  variant
}: LoadingFullScreenProps) => {
  return (
    <FullScreenCenter
      style={
        variant === "overlay"
          ? { background: "rgba(0,0,0,0.5)", color: "white" }
          : undefined
      }
    >
      <CircularProgress />
      {message && (
        <Box m={1}>
          <Typography variant="h4">{message}</Typography>
        </Box>
      )}
      {cancel && (
        <Button variant="contained" onClick={cancel}>
          Cancel
        </Button>
      )}
    </FullScreenCenter>
  );
};
