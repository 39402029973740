import React from "react";
import { useLocation } from "react-router-dom";
import {
  Outer,
  CustomCollapse as Collapse,
  CustomLink as Link
} from "./SubNavComponents";
import { RoutingElement, RoutingObject } from "./helpers";
import List from "@material-ui/core/List";
import { KeyboardArrowRight, KeyboardArrowDown } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import { Box, fade } from "@material-ui/core";
import { useAuth } from "@akj-dev/affinity-platform";
import { darken, makeStyles, Theme } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { activeThemeBrand, AppTheme } from "@akj-dev/affinity-platform";
import { SubNavLogo } from "./SubNavLogo";

interface SubNavProps {
  routes: RoutingObject;
  rootTitle: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.common.white
  },
  focusVisible: {
    "&$focusVisible": {
      backgroundColor: fade(theme.palette.action.selected, 0.25)
    }
  },
  selected: {
    "&$selected, &$selected:hover": {
      backgroundColor: fade(theme.palette.action.selected, 0.25)
    }
  },
  button: {
    "&:hover": {
      backgroundColor: fade(theme.palette.action.selected, 0.25)
    }
  },
  divider: {
    borderBottom: (() => {
      switch (activeThemeBrand()) {
        case AppTheme.AFFINITY:
          return `1px solid ${theme.palette.grey[400]}`;
        case AppTheme.DWS:
          return "1px solid #7D788C"; // TODO: Should this be a definition in the theme provider? What's it's general usage?
        case AppTheme.VODAFONE:
        default:
          return `1px solid ${darken(theme.palette.primary.main, 0.2)}`;
      }
    })()
  },
  gutters: {
    paddingLeft: (level: number) => 16 + level * 16
  }
}));

export const SubNav: React.FC<SubNavProps> = ({ routes }) => {
  const { externalUserName } = useAuth();
  return (
    <Outer data-cy="SubNav">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        mx={2}
        mt={2}
        mb={1}
      >
        <Link to="/">
          <SubNavLogo
            width={190}
            height={50}
            style={{ pointerEvents: "none" }}
          />
        </Link>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        mx={2}
        mt={1}
        mb={1}
      >
        <Typography variant="h5" color="inherit" align="center">
          Welcome, {externalUserName}
        </Typography>
      </Box>
      <List component="nav">
        <SubNavLevel routes={routes.hierarchy} level={0} />
      </List>
    </Outer>
  );
};

interface SubNavLevelProps {
  routes: any;
  level: number;
}

const SubNavLevel: React.FC<SubNavLevelProps> = ({ routes, level }) => {
  const classes = useStyles(level);
  const { pathname } = useLocation();
  const [open, setOpen] = React.useState(false);
  return (
    <>
      {routes.map((route: RoutingElement, i: number) => {
        const hasSubmenu = Array.isArray(route.subject);
        if (hasSubmenu)
          return (
            <React.Fragment key={i}>
              <ListItem
                button
                onClick={() => setOpen(!open)}
                disableRipple
                disableTouchRipple
                classes={{
                  button: classes.button,
                  divider: classes.divider,
                  gutters: classes.gutters
                }}
                divider
              >
                {route.icon && (
                  <ListItemIcon
                    classes={{
                      root: classes.root
                    }}
                  >
                    {route.icon}
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={
                    <Typography variant="body2" color="inherit" noWrap>
                      {route.title}
                    </Typography>
                  }
                />
                {open ? (
                  <KeyboardArrowDown fontSize="small" />
                ) : (
                  <KeyboardArrowRight fontSize="small" />
                )}
              </ListItem>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="nav" disablePadding>
                  <SubNavLevel routes={route.subject} level={level + 1} />
                </List>
              </Collapse>
            </React.Fragment>
          );
        return (
          <Link to={route.path} key={i}>
            <ListItem
              button
              disableRipple
              disableTouchRipple
              selected={pathname === route.path}
              classes={{
                root: classes.root,
                focusVisible: classes.focusVisible,
                selected: classes.selected,
                button: classes.button,
                divider: classes.divider,
                gutters: classes.gutters
              }}
              divider
            >
              {route.icon && (
                <ListItemIcon
                  classes={{
                    root: classes.root
                  }}
                >
                  {route.icon}
                </ListItemIcon>
              )}
              <ListItemText
                primary={
                  <Typography variant="body2" color="inherit" noWrap>
                    {route.title}
                  </Typography>
                }
              />
            </ListItem>
          </Link>
        );
      })}
    </>
  );
};
