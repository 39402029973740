import React, { createContext, useContext, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    close: {
      padding: theme.spacing(0.5)
    }
  })
);

export interface SnackbarMessage {
  message: string;
  key: number;
}

interface addSnackbar {
  (message: string, variant: "info" | "loading" | "error"): void;
}

interface SnackbarContext {
  addSnackbar: addSnackbar;
}

const SnackbarContext = createContext<SnackbarContext>(null as any);
export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarProvider: React.FC = ({ children }) => {
  const [snackPack, setSnackPack] = useState<SnackbarMessage[]>([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState<SnackbarMessage | undefined>(
    undefined
  );

  React.useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack(prev => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const addSnackbar: addSnackbar = (message, variant) => {
    setSnackPack(prev => [...prev, { message, key: new Date().getTime() }]);
  };

  const handleClose = (
    event: React.SyntheticEvent | MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const classes = useStyles();

  return (
    <SnackbarContext.Provider value={{ addSnackbar }}>
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        onExited={handleExited}
        message={messageInfo ? messageInfo.message : undefined}
        action={
          <React.Fragment>
            {/*<Button color="secondary" size="small" onClick={handleClose}>*/}
            {/*  UNDO*/}
            {/*</Button>*/}
            <IconButton
              aria-label="close"
              color="inherit"
              className={classes.close}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      />
      {children}
    </SnackbarContext.Provider>
  );
};
