import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DataTableSortOrder } from "./DataTable";
import { ArrowDownward } from "@material-ui/icons";
import { TableCell } from "@material-ui/core";

interface SortableColumnHeaderProps {
  dir: DataTableSortOrder;
  onClick: () => void;
  children: ReactNode;
}

const useStyles = makeStyles(theme => ({
  root: {
    cursor: "pointer"
  },
  inner: {
    display: "flex",
    alignItems: "center"
  },
  label: {
    marginRight: theme.spacing(1)
  },
  icon: {
    visibility: (props: SortableColumnHeaderProps) =>
      props.dir === DataTableSortOrder.None ? "hidden" : "visible",
    transform: (props: SortableColumnHeaderProps) =>
      props.dir === DataTableSortOrder.Asc ? "rotate(180deg)" : "none",
    transition: "transform .3s"
  }
}));

export const SortableColumnHeader = (props: SortableColumnHeaderProps) => {
  const classes = useStyles(props);
  return (
    <TableCell onClick={props.onClick} className={classes.root}>
      <div className={classes.inner}>
        <div className={classes.label}>{props.children}</div>
        <ArrowDownward className={classes.icon} />
      </div>
    </TableCell>
  );
};
