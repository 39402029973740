/**
 * DS brand. See:
 * https://projects.invisionapp.com/dsm/aurora-ux-d/brand-guidelines/applications/data-export/json
 *
 * then API call to get the below:
 * https://projects.invisionapp.com/dsm-export/aurora-ux-d/brand-guidelines/style-data.json?exportFormat=lookup&key=BkUPhquFV
 *
 */

const brandStyleData = {
  lookup: {
    colors: {
      vibrantBlue: {
        name: "Vibrant Blue",
        value: "#27a0f2"
      },
      vibrantTeal: {
        name: "Vibrant Teal",
        value: "#1ae2c0"
      },
      vibrantGreen: {
        name: "Vibrant Green",
        value: "#6ce85b"
      },
      vibrantYellow: {
        name: "Vibrant Yellow",
        value: "#f2e953"
      },
      vibrantOrange: {
        name: "Vibrant Orange",
        value: "#fc8f1a"
      },
      vibrantRed: {
        name: "Vibrant Red",
        value: "#f43b22"
      },
      vibrantPink: {
        name: "Vibrant Pink",
        value: "#e23bb7"
      },
      vibrantViolet: {
        name: "Vibrant Violet",
        value: "#7a5aff"
      },
      affinityBlueUltraDark: {
        name: "Affinity Blue Ultra Dark",
        value: "#145073"
      },
      affinityBlueDarker: {
        name: "Affinity Blue Darker",
        value: "#1a648f"
      },
      affinityBlueDark: {
        name: "Affinity Blue Dark",
        value: "#2386be"
      },
      affinityBlue: {
        name: "Affinity Blue",
        value: "#2ca7ee"
      },
      affinityBlueLight: {
        name: "Affinity Blue Light",
        value: "#56b9f1"
      },
      affinityBlueLighter: {
        name: "Affinity Blue Lighter",
        value: "#80caf5"
      },
      affinityTealUltraDark: {
        name: "Affinity Teal Ultra Dark",
        value: "#183b35"
      },
      affinityTealDarker: {
        name: "Affinity Teal Darker",
        value: "#2f766a"
      },
      affinityTealDark: {
        name: "Affinity Teal Dark",
        value: "#3e9d8d"
      },
      affinityTeal: {
        name: "Affinity Teal",
        value: "#4ec4b0"
      },
      affinityTealLight: {
        name: "Affinity Teal Light",
        value: "#71d0c0"
      },
      affinityTealLighter: {
        name: "Affinity Teal Lighter",
        value: "#95dcd0"
      },
      affinityGreenUltraDark: {
        name: "Affinity Green Ultra Dark",
        value: "#254f2c"
      },
      affinityGreenDarker: {
        name: "Affinity Green Darker",
        value: "#3d844a"
      },
      affinityGreenDark: {
        name: "Affinity Green Dark",
        value: "#51b063"
      },
      affinityGreen: {
        name: "Affinity Green",
        value: "#65dc7c"
      },
      affinityGreenLight: {
        name: "Affinity Green Light",
        value: "#84e396"
      },
      affinityGreenLighter: {
        name: "Affinity Green Lighter",
        value: "#a3eab0"
      },
      affinityYellowUltraDark: {
        name: "Affinity Yellow Ultra Dark",
        value: "#7f804b"
      },
      affinityYellowDarker: {
        name: "Affinity Yellow Darker",
        value: "#989959"
      },
      affinityYellowDark: {
        name: "Affinity Yellow Dark",
        value: "#cacc77"
      },
      affinityYellow: {
        name: "Affinity Yellow",
        value: "#fdff95"
      },
      affinityYellowLight: {
        name: "Affinity Yellow Light",
        value: "#fdffaa"
      },
      affinityYellowLighter: {
        name: "Affinity Yellow Lighter",
        value: "#feffbf"
      },
      affinityOrangeUltraDark: {
        name: "Affinity Orange Ultra Dark",
        value: "#805919"
      },
      affinityOrangeDarker: {
        name: "Affinity Orange Darker",
        value: "#996b1d"
      },
      affinityOrangeDark: {
        name: "Affinity Orange Dark",
        value: "#cc8e27"
      },
      affinityOrange: {
        name: "Affinity Orange",
        value: "#ffb231"
      },
      affinityOrangeLight: {
        name: "Affinity Orange Light",
        value: "#ffc15a"
      },
      affinityOrangeLighter: {
        name: "Affinity Orange Lighter",
        value: "#ffd183"
      },
      affinityRedUltraDark: {
        name: "Affinity Red Ultra Dark",
        value: "#661912"
      },
      affinityRedDarker: {
        name: "Affinity Red Darker",
        value: "#99261b"
      },
      affinityRedDark: {
        name: "Affinity Red Dark",
        value: "#cc3224"
      },
      affinityRed: {
        name: "Affinity Red",
        value: "#ff3f2d"
      },
      affinityRedLight: {
        name: "Affinity Red Light",
        value: "#ff6557"
      },
      affinityRedLighter: {
        name: "Affinity Red Lighter",
        value: "#ff8c81"
      },
      affinityPinkUltraDark: {
        name: "Affinity Pink Ultra Dark",
        value: "#66164a"
      },
      affinityPinkDarker: {
        name: "Affinity Pink Darker",
        value: "#7a1a59"
      },
      affinityPinkDark: {
        name: "Affinity Pink Dark",
        value: "#a22276"
      },
      affinityPink: {
        name: "Affinity Pink",
        value: "#cb2b94"
      },
      affinityPinkLight: {
        name: "Affinity Pink Light",
        value: "#d555a9"
      },
      affinityPinkLighter: {
        name: "Affinity Pink Lighter",
        value: "#e080bf"
      },
      affinityVioletUltraDark: {
        name: "Affinity Violet Ultra Dark",
        value: "#322e4a"
      },
      affinityVioletDarker: {
        name: "Affinity Violet Darker",
        value: "#4a466e"
      },
      affinityVioletDark: {
        name: "Affinity Violet Dark",
        value: "#635d93"
      },
      affinityViolet: {
        name: "Affinity Violet",
        value: "#7c74b8"
      },
      affinityVioletLight: {
        name: "Affinity Violet Light",
        value: "#9690c6"
      },
      affinityVioletLighter: {
        name: "Affinity Violet Lighter",
        value: "#b0acd4"
      },
      neutralBlack: {
        name: "Neutral Black",
        value: "#000000"
      },
      neutralAbyss: {
        name: "Neutral Abyss",
        value: "#140f1e"
      },
      neutralNight: {
        name: "Neutral Night",
        value: "#1a142b"
      },
      neutralDeepPurple: {
        name: "Neutral Deep Purple",
        value: "#271d3e"
      },
      neutralStorm: {
        name: "Neutral Storm",
        value: "#524b65"
      },
      neutralSteel: {
        name: "Neutral Steel",
        value: "#7d788c"
      },
      neutralCloud: {
        name: "Neutral Cloud",
        value: "#a9a5b2"
      },
      neutralSilverfox: {
        name: "Neutral Silverfox",
        value: "#d3d2d7"
      },
      neutralGhost: {
        name: "Neutral Ghost",
        value: "#e9e9ec"
      },
      neutralAthensGrey: {
        name: "Neutral Athens Grey",
        value: "#f8f7f9"
      },
      neutralWhite: {
        name: "Neutral White",
        value: "#ffffff"
      },
      vibrantVioletUltraDark: {
        name: "Vibrant Violet Ultra Dark",
        value: "#312466"
      },
      vibrantVioletDarker: {
        name: "Vibrant Violet Darker",
        value: "#493699"
      },
      vibrantVioletDark: {
        name: "Vibrant Violet Dark",
        value: "#6248cc"
      },
      vibrantVioletLight: {
        name: "Vibrant Violet Light",
        value: "#a28cff"
      },
      vibrantVioletLighter: {
        name: "Vibrant Violet Lighter",
        value: "#e0d8ff"
      },
      vibrantVioletUltraLight: {
        name: "Vibrant Violet Ultra Light",
        value: "#E4DEFF"
      },
      affinityVioletUltraLight: {
        name: "Affinity Violet Ultra Light",
        value: "#e5e3f1"
      },
      affinityPinkUltraLight: {
        name: "Affinity Pink Ultra Light",
        value: "#f5d5ea"
      },
      affinityRedUltraLight: {
        name: "Affinity Red Ultra Light",
        value: "#ffd9d5"
      },
      affinityBlueUltraLight: {
        name: "Affinity Blue Ultra Light",
        value: "#d5edfc"
      },
      affinityTealUltraLight: {
        name: "Affinity Teal Ultra Light",
        value: "#dcf3ef"
      },
      affinityGreenUltraLight: {
        name: "Affinity Green Ultra Light",
        value: "#e0f8e5"
      },
      affinityYellowUltraLight: {
        name: "Affinity Yellow Ultra Light",
        value: "#ffffea"
      },
      affinityOrangeUltraLight: {
        name: "Affinity Orange Ultra Light",
        value: "#fff0d6"
      }
    },
    fonts: {
      montserrat: {
        name: "Montserrat",
        family: "Montserrat",
        fallback: "Ariel",
        variants: [
          {
            fontStyle: "normal",
            fontWeight: 300
          },
          {
            fontStyle: "italic",
            fontWeight: 300
          },
          {
            fontStyle: "normal",
            fontWeight: 600
          },
          {
            fontStyle: "italic",
            fontWeight: 600
          }
        ]
      },
      openSansCondensed: {
        name: "Open Sans Condensed",
        family: "Open Sans Condensed",
        fallback: "Ariel",
        variants: [
          {
            fontStyle: "normal",
            fontWeight: 300
          },
          {
            fontStyle: "italic",
            fontWeight: 300
          },
          {
            fontStyle: "normal",
            fontWeight: "bold"
          }
        ]
      },
      openSans: {
        name: "Open Sans",
        family: "Open Sans",
        fallback: "Ariel",
        variants: [
          {
            fontStyle: "normal",
            fontWeight: 400
          },
          {
            fontStyle: "italic",
            fontWeight: 400
          },
          {
            fontStyle: "normal",
            fontWeight: "bold"
          },
          {
            fontStyle: "italic",
            fontWeight: "bold"
          }
        ]
      },
      arial: {
        name: "Arial",
        family: "Arial",
        variants: [
          {
            fontStyle: "normal",
            fontWeight: 400
          },
          {
            fontStyle: "italic",
            fontWeight: 400
          },
          {
            fontStyle: "normal",
            fontWeight: "bold"
          },
          {
            fontStyle: "italic",
            fontWeight: "bold"
          }
        ]
      },
      openSansSemiBold: {
        name: "Open Sans SemiBold",
        family: "Open Sans SemiBold",
        variants: [
          {
            fontStyle: "normal",
            fontWeight: 600
          },
          {
            fontStyle: "italic",
            fontWeight: 600
          }
        ]
      },
      helvetica: {
        name: "Helvetica",
        family: "Helvetica",
        variants: [
          {
            fontStyle: "normal",
            fontWeight: 400
          },
          {
            fontStyle: "italic",
            fontWeight: 400
          },
          {
            fontStyle: "normal",
            fontWeight: 300
          },
          {
            fontStyle: "italic",
            fontWeight: 300
          },
          {
            fontStyle: "normal",
            fontWeight: "bold"
          },
          {
            fontStyle: "italic",
            fontWeight: "bold"
          }
        ]
      }
    },
    typeStyles: {
      body: {
        fontSize: "24px",
        lineHeight: "normal",
        textAlign: "left",
        color: null,
        fontStyle: "normal",
        fontWeight: "400",
        fontFamily: "Open Sans, Ariel",
        name: "Body",
        backgroundColor: null
      },
      bodyBold: {
        fontSize: "24px",
        lineHeight: "normal",
        textAlign: "start",
        color: null,
        fontStyle: "normal",
        fontWeight: "600",
        fontFamily: "Open Sans SemiBold",
        name: "Body Bold",
        backgroundColor: null
      },
      bodyCondensed: {
        fontSize: "24px",
        lineHeight: "normal",
        textAlign: "start",
        color: null,
        letterSpacing: "1.8px",
        textTransform: "uppercase",
        fontStyle: "normal",
        fontWeight: "400",
        fontFamily: "Open Sans, Ariel",
        name: "Body Condensed",
        backgroundColor: null
      },
      bodyCondensedBold: {
        fontSize: "24px",
        lineHeight: "normal",
        textAlign: "start",
        color: null,
        letterSpacing: "1.8px",
        textTransform: "uppercase",
        fontStyle: "normal",
        fontWeight: "bold",
        fontFamily: "Open Sans, Ariel",
        name: "Body Condensed Bold",
        backgroundColor: null
      },
      heading: {
        fontSize: "36px",
        lineHeight: "normal",
        textAlign: "start",
        color: null,
        fontStyle: "normal",
        fontWeight: "600",
        fontFamily: "Montserrat, Ariel",
        name: "Heading",
        backgroundColor: null
      },
      subHeading: {
        fontSize: "24px",
        lineHeight: "normal",
        textAlign: "start",
        color: null,
        fontStyle: "normal",
        fontWeight: "300",
        fontFamily: "Montserrat, Ariel",
        name: "Sub Heading",
        backgroundColor: null
      },
      fallbackBody: {
        fontSize: "24px",
        lineHeight: "normal",
        textAlign: "left",
        color: null,
        fontStyle: "normal",
        fontWeight: "400",
        fontFamily: "Arial",
        name: "Fallback Body",
        backgroundColor: null
      },
      fallbackBold: {
        fontSize: "24px",
        lineHeight: "normal",
        textAlign: "left",
        color: null,
        fontStyle: "normal",
        fontWeight: "bold",
        fontFamily: "Arial",
        name: "Fallback Bold",
        backgroundColor: null
      }
    }
  }
};

const brandColors = brandStyleData.lookup.colors;

const themeColors: any = {};
themeColors.primary = brandColors.vibrantViolet;
themeColors.primaryLight = brandColors.vibrantVioletUltraLight;
themeColors.primaryDark = brandColors.vibrantVioletDarker;

themeColors.secondary = brandColors.affinityTeal;
themeColors.secondaryLight = brandColors.affinityTealUltraLight;
themeColors.secondaryDark = brandColors.affinityTealDarker;

themeColors.error = brandColors.affinityRedDark;
themeColors.errorLight = brandColors.affinityRedUltraLight;
themeColors.errorDark = brandColors.affinityRedDarker;

themeColors.warning = brandColors.affinityOrange;
themeColors.warningLight = brandColors.affinityOrangeUltraLight;
themeColors.warningDark = brandColors.affinityOrangeDarker;

themeColors.info = brandColors.affinityBlue;
themeColors.infoLight = brandColors.affinityBlueUltraLight;
themeColors.infoDark = brandColors.affinityBlueDarker;

themeColors.success = brandColors.affinityGreenDark;
themeColors.successLight = brandColors.affinityGreenUltraLight;
themeColors.successDark = brandColors.affinityGreenDarker;

themeColors.grey50 = brandColors.neutralAthensGrey;
themeColors.grey100 = brandColors.neutralGhost;
themeColors.grey200 = brandColors.neutralSilverfox;
themeColors.grey300 = brandColors.neutralCloud;
themeColors.grey400 = brandColors.neutralSteel;
themeColors.grey500 = brandColors.neutralStorm;
themeColors.grey600 = brandColors.neutralDeepPurple;
themeColors.grey700 = brandColors.neutralNight;
themeColors.grey800 = brandColors.neutralAbyss;
themeColors.grey900 = brandColors.neutralBlack;

themeColors.textPrimary = { value: "rgba(39,29,62,0.87)" }; // of neutralDeepPurple
themeColors.textSecondary = { value: "rgba(39,29,62,0.54)" }; // of neutralDeepPurple
themeColors.textDisabled = { value: "rgba(39,29,62,0.38)" }; // of neutralDeepPurple

themeColors.actionActive = { value: "rgba(39,29,62,0.38)" }; // of neutralDeepPurple
themeColors.actionHover = { value: "rgba(39,29,62,0.02)" }; // of neutralDeepPurple
themeColors.actionSelected = { value: "rgba(39,29,62,0.01)" }; // of neutralDeepPurple
themeColors.actionDisabled = { value: "rgba(39,29,62,0.54)" }; // of neutralDeepPurple
themeColors.actionDisabledBackground = { value: "rgba(39,29,62,0.12)" }; // of neutralDeepPurple

themeColors.bgGrey = brandColors.neutralAthensGrey;
themeColors.bgPaper = brandColors.neutralWhite;

themeColors.divider = { value: "rgba(39,29,62,0.12)" }; // of neutralDeepPurple

themeColors.commonBlack = brandColors.neutralBlack;
themeColors.commonWhite = brandColors.neutralWhite;

themeColors.loading = brandColors.vibrantViolet;

// TODO: This whole thing is way too complex. We're just defining colours.

export const affinityColors = { ...brandColors, ...themeColors };
export const fonts = brandStyleData.lookup.fonts;
export const typeStyles = brandStyleData.lookup.typeStyles;
