import React, { ReactNode, useState } from "react";
import {
  Box,
  Card,
  CardHeader,
  LinearProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from "@material-ui/core";
import { StatusChip } from "../StatusChip/StatusChip";
import { SvgIconComponent, SpaceBar, More } from "@material-ui/icons";
import { SortableColumnHeader } from "./SortableColumnHeader";
import { LoaderButton } from "../LoaderButton/LoaderButton";

export interface DataTableProps {
  columns: DataTableColumn[];
  data?: ReactNode[][];
  loading?: boolean;
  error?: string; // or node?
  title?: string;
  NoDataIcon?: SvgIconComponent;
  noDataCTA?: ReactNode;

  pagination?: DataTablePagination;

  // TablePagination TODO Union type so others depend on count? https://stackoverflow.com/questions/54587188
  rowsPerPage?: number;
  page?: number;
  onChangePage?: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void;
  onChangeRowsPerPage?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;

  // Load More (alternative to pagination)
  onLoadMore?: () => void;
  count?: number;

  onChangeSort?: (sortState: DataTableSortState) => void;
}

export type DataTableColumn = {
  name: string;
  label: string;
  sortable?: boolean;
};

/**
 * Sort order for display on column headers
 * Note: None is necessary as default sort order may be by a column not surfaced in the table.
 * Without it, user would have no way of reverting to default.
 */
export enum DataTableSortOrder {
  Asc = "ASC",
  Desc = "DESC",
  None = ""
}

export type DataTableSortState = {
  columnIndex: number;
  dir: DataTableSortOrder;
};

export enum DataTablePagination {
  Infinite = "infinite",
  Pages = "pages"
}

export const DataTable = ({
  title,
  columns,
  data,
  loading,
  onLoadMore,
  error,
  count,
  pagination = DataTablePagination.Infinite,
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
  onChangeSort,
  NoDataIcon = SpaceBar,
  noDataCTA = "No data."
}: DataTableProps) => {
  const classes = useStyles();
  const [sort, setSort] = useState<DataTableSortState>({
    columnIndex: -1,
    dir: DataTableSortOrder.None
  });

  /**
   * Toggle column sorting state
   * Note this does not sort locally as the result would never be accurate for paginated data.
   * Sorting should be from the server response and/or update in the data layer using this component.
   * @param columnIndex
   */
  const toggleSort = (columnIndex: number) => {
    let newDir = DataTableSortOrder.Asc;
    if (sort.dir === DataTableSortOrder.Asc) newDir = DataTableSortOrder.Desc;
    if (sort.dir === DataTableSortOrder.Desc) newDir = DataTableSortOrder.None;
    const newSort = { columnIndex, dir: newDir };
    setSort(newSort);
    onChangeSort && onChangeSort(newSort);
  };

  return (
    <Card>
      {title && <CardHeader className={classes.header} title={title} />}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, i) => {
                if (column.sortable)
                  return (
                    <SortableColumnHeader
                      key={i}
                      onClick={() => toggleSort(i)}
                      dir={
                        sort.columnIndex === i
                          ? sort.dir
                          : DataTableSortOrder.None
                      }
                    >
                      {column.label}
                    </SortableColumnHeader>
                  );
                return <TableCell key={i}>{column.label}</TableCell>;
              })}
            </TableRow>
            {loading && (
              <TableRow>
                <TableCell colSpan={columns.length} style={{ padding: 0 }}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
            {error && (
              <TableRow>
                <TableCell colSpan={columns.length} style={{ padding: 0 }}>
                  <StatusChip type="error" message={error} />
                </TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {data?.length
              ? data.map((row: any, i: number) => (
                  <TableRow key={i}>
                    {row.map((row: any, i: number) => (
                      <TableCell key={i}>{row}</TableCell>
                    ))}
                  </TableRow>
                ))
              : !error &&
                !loading && (
                  <TableRow>
                    <TableCell colSpan={columns.length}>
                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        m={2}
                      >
                        <NoDataIcon color="primary" style={{ fontSize: 100 }} />
                        <Box mt={2}>{noDataCTA}</Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination === DataTablePagination.Infinite && (
        <div className={classes.footer}>
          {onLoadMore && (
            <LoaderButton
              loading={!!loading}
              onClick={onLoadMore}
              variant="outlined"
              startIcon={<More />}
            >
              Load More
            </LoaderButton>
          )}
          {count && <div className={classes.count}>Total: {count}</div>}
        </div>
      )}
      {pagination === DataTablePagination.Pages && (
        <React.Fragment>
          {count && rowsPerPage && onChangePage && onChangeRowsPerPage ? (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page || 0}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
            />
          ) : null}
        </React.Fragment>
      )}
    </Card>
  );
};

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.grey[600],
    color: theme.palette.primary.contrastText
  },
  footer: {
    display: "flex",
    margin: theme.spacing(1),
    alignItems: "center"
  },
  count: {
    marginLeft: "auto",
    color: theme.palette.text.disabled
  }
}));
