import {
  affinityBrand,
  vodaBrand,
  daisyBrand,
  dwpBrand
} from "@akj-dev/design-system";

import { AppTheme } from "./theme";
import { hostToBrand } from "./hostToBrand";

export function useBrand() {
  switch (hostToBrand(window.location.hostname)) {
    case AppTheme.VODAFONE:
      return vodaBrand;
    case AppTheme.DAISY:
      return daisyBrand;
    case AppTheme.DWS:
      return dwpBrand;
    case AppTheme.AFFINITY:
    default:
      return affinityBrand;
  }
}
