import React from "react";

import GraphiQLApp from "graphiql";
import { FetcherParams } from "@graphiql/toolkit";

import { useAuth } from "@akj-dev/affinity-platform";

export const GraphiQL = () => {
  const { apiFetch } = useAuth();
  return (
    <div style={{ height: "100%" }}>
      <GraphiQLApp
        fetcher={async (graphQLParams: FetcherParams) => {
          const data = await apiFetch("/graphql", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            },
            body: JSON.stringify(graphQLParams),
            credentials: "same-origin"
          });
          return data.json().catch(() => data.text());
        }}
      />
    </div>
  );
};
