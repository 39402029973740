/**
 * Convert px to rem
 * MUI helper but not accessible here.
 * See packages/material-ui/src/styles/createTypography.js
 *
 * @param size
 * @returns {string}
 */
function pxToRem(size) {
  const fontSize = 14;
  const htmlFontSize = 16;
  const coef = fontSize / 14;
  return `${(size / htmlFontSize) * coef}rem`;
}

// See https://material-ui.com/customization/default-theme/ for avaliable options
export const baseThemeOptions = {
  spacing: 16, // TBC
  shape: {
    borderRadius: 5
  },
  props: {
    MuiCard: {
      elevation: 4
    }
  },
  typography: {
    // Note: 16px default htmlFontSize base.
    // htmlFontSize: 16,
    fontSize: 14, // default base
    fontFamily: "'Open Sans', sans-serif",
    fontWeightBold: 600,
    h1: {
      fontSize: "2rem",
      fontWeight: 600
    },
    h2: {
      fontSize: "1.75rem",
      fontWeight: 600
    },
    h3: {
      fontSize: "1.25rem",
      fontWeight: 600
    },
    h4: {
      fontSize: "1rem",
      fontWeight: 600
    },
    h5: {
      fontSize: "0.875rem",
      fontWeight: 600
    },
    h6: {
      // Note: This is "Supporting text" in the DS
      fontSize: "1rem",
      fontWeight: 600 // TODO: this is DialogTitle, which doesn't really fit... change h* assignment
    },
    button: {
      fontWeight: 600,
      textTransform: "none",
      fontSize: "0.875rem"
    },
    caption: {
      fontSize: pxToRem(12)
    }
  }
};

/**
 * Create muiTheme JSS overrides based from an existing MuiTheme
 *
 * @param theme an existing muiTheme generated via createMuiTheme()
 * @returns {object} an overrides object suitable for use in a config object passed to createMuiTheme()
 */
export const createOverrides = theme => ({
  MuiTypography: {
    gutterBottom: {
      marginBottom: "2rem"
    }
  },
  MuiButton: {
    root: {
      fontWeight: 600,
      fontSize: pxToRem(14)
    },
    contained: {
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none"
      }
    },
    containedSecondary: {
      color: theme.palette.common.white
    },
    sizeLarge: {
      fontSize: pxToRem(14),
      padding: "12px 24px"
    },
    sizeSmall: {
      padding: "3px 12px",
      fontSize: pxToRem(14)
    }
  },
  MuiButtonGroup: {
    grouped: {
      fontSize: pxToRem(12),
      fontWeight: 400
    }
  },
  MuiOutlinedInput: {
    input: {
      "&.Mui-disabled": {
        backgroundColor: "#e9e9ec"
      }
    }
  },
  MuiFormLabel: {
    root: {
      fontSize: pxToRem(14)
    }
  },
  MuiCardHeader: {
    title: {
      fontSize: "1rem"
    }
  },
  MuiTableCell: {
    root: {
      borderBottomColor: theme.palette.grey[200]
    },
    head: {
      color: "rgba(0, 0, 0, 0.54)", // todo: link secondary text color
      fontSize: ".75rem",
      fontWeight: 600 // default is 500 but we don't currently pull in that weight in font link tag
    }
  },
  MUIDataTableToolbar: {
    root: {
      "@media (min-width: 960px)": {
        paddingLeft: "24px",
        paddingRight: "24px"
      },
      color: theme.palette.common.white,
      backgroundColor: theme.palette.grey[600],
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
      minHeight: "48px",
      "& button[aria-label='Filter Table'], & button[aria-label='Search']": {
        color: theme.palette.common.white
      },
      "& $left h6": {
        // subtitle
        fontWeight: 600,
        fontSize: "14px"
      }
    }
  },
  MUIDataTableSearch: {
    clearIcon: {
      color: theme.palette.common.white
    },
    searchIcon: {
      color: theme.palette.common.white
    },
    searchText: {
      "& > div": {
        background: theme.palette.common.white,
        marginTop: "14px"
      }
    }
  },
  MUIDataTableFilter: {
    root: {
      backgroundColor: theme.palette.common.white,
      width: "368px",
      paddingTop: "18px",
      paddingBottom: "26px",
      "& $header": {
        paddingTop: "8px",
        paddingBottom: "8px"
      },
      "& $title": {
        marginLeft: 0,
        fontWeight: 600,
        textTransform: "lowercase",
        "&:first-letter": {
          textTransform: "uppercase"
        }
      },
      "& $resetLink": {
        fontSize: "14px",
        padding: 0,
        marginLeft: "8px",
        transform: "translateY(-1px)" // shame ಠ_ಠ
      }
    }
  },
  MuiGridListTile: {
    // filter modal items
    // some library styles are applied as style tags for this particular element, hence !important
    root: {
      width: "100% !important",
      "& $tile": {
        overflow: "initial", // fix input shrink-label being hidden
        marginTop: 0
      },
      paddingTop: "8px !important", // provide some room for input shrink label
      paddingBottom: "8px !important", // compensate for paddingTop
      "&:last-of-type": {
        paddingBottom: "16px !important" // compensate for paddingTop
      }
    }
  },
  MUIDataTableFilterList: {
    root: {
      // chip container
      marginTop: "8px",
      "& $chip": {
        marginBottom: "8px"
      }
    }
  },
  MUIDataTableHeadRow: {
    root: {
      "& th": {
        border: "none"
      }
    }
  },
  MUIDataTable: {
    responsiveScrollFullHeight: {
      "@media (min-width: 960px)": {
        padding: "0px 24px"
      }
    }
  },
  MUIDataTableHeadCell: {
    root: {
      fontSize: "inherit",
      color: "inherit"
    },
    sortAction: {
      alignItems: "center"
    }
  },
  MuiTableRow: {
    root: {
      // removes selected row highlights
      "&.Mui-selected": {
        background: "none"
      },
      "&.Mui-selected:hover": {
        background: "none"
      }
    }
  },
  MuiTablePagination: {
    caption: {
      "&:first-of-type": {
        display: "none" // hide "Rows Per Page" caption
      }
    }
  }
});
