import React from "react";
import { Voyager } from "graphql-voyager";
import { useAuth } from "@akj-dev/affinity-platform";

/**
 * GraphQL Voyager introspecting the API served by:
 * https://gitlab.com/akj-dev/affinity-sales-backend/
 *
 * Following:
 * https://github.com/APIs-guru/graphql-voyager#using-as-a-dependency
 *
 * @constructor
 */
export const GraphQLVoyager = () => {
  const { apiFetch } = useAuth();

  function introspectionProvider(query: any) {
    return apiFetch("/graphql", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ query: query })
    }).then(response => response.json());
  }

  return (
    <Voyager
      introspection={introspectionProvider}
      workerURI="https://cdn.jsdelivr.net/npm/graphql-voyager/dist/voyager.worker.js"
    />
  );
};
