import React from "react";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { ThemeProvider as SCThemeProvider } from "styled-components";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { getTheme } from "@akj-dev/affinity-platform";

//@ts-ignore
//window.theme = THEME; // uncomment this for debugging only - DO NOT ACCESS THIS VARIABLE IN YOUR CODE :-)

/**
 * This is the wrapper for any app launched in standalone mode
 * i.e. Outside of the Affinity Platform
 *
 * Note the extra SCThemeProvider is so styled components can access the MUI
 * theme easily. See:
 * https://github.com/mui-org/material-ui/issues/10098#issuecomment-574162533
 *
 * @param children
 * @returns {*}
 * @constructor
 */
export function MUIProviders({ children }: React.PropsWithChildren<any>) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={getTheme()}>
        <SCThemeProvider theme={getTheme()}>
          <CssBaseline />
          {children}
        </SCThemeProvider>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}
