import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { MUIProviders, UATBanner, LoginScreen } from "@akj-dev/affinity-suite";
import { ErrorFullScreen, LoadingFullScreen } from "@akj-dev/design-system";
import { AuthProvider } from "@akj-dev/affinity-platform";
import { GraphQLVoyager } from "./screens/GraphQLVoyager";
import Cookies from "js-cookie";
import { Nav } from "./components/Nav";
import { GraphiQL } from "./screens/GraphiQL";

/**
 * Internal Dev Tools
 */
function AppStandalone() {
  const [authAPIBaseUrl, setAuthAPIBaseUrl] = useState(
    Cookies.get("ap_uatBannerAuthApi") ||
      "https://pub-external-users-testenv01.uat.aurora.io/api/v2/"
  );

  return (
    <MUIProviders>
      <AuthProvider
        authApiBaseUrl={authAPIBaseUrl}
        initScreen={() => (
          <LoadingFullScreen message="Loading user session..." />
        )}
        loginScreen={(handleLogin, loading, error) => (
          <>
            <UATBanner
              authAPIBaseUrl={authAPIBaseUrl}
              setAuthAPIBaseUrl={setAuthAPIBaseUrl}
            />
            <LoginScreen
              handleLogin={handleLogin}
              loading={loading}
              error={error}
            />
          </>
        )}
        errorScreen={(message, retry) => (
          <ErrorFullScreen
            message={`Authentication error: ${message}`}
            retry={retry}
          />
        )}
      >
        <UATBanner
          authAPIBaseUrl={authAPIBaseUrl}
          setAuthAPIBaseUrl={setAuthAPIBaseUrl}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 120,
            left: 0,
            right: 0
          }}
        >
          <Router>
            <Nav />
            <Switch>
              <Route path="/voyager">
                <GraphQLVoyager />
              </Route>
              <Route path="/graphiql">
                <GraphiQL />
              </Route>
            </Switch>
          </Router>
        </div>
      </AuthProvider>
    </MUIProviders>
  );
}

export default AppStandalone;
