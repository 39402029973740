import React from "react";
import { makeStyles, CircularProgress } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import CheckIcon from "@material-ui/icons/Check";
import InfoIcon from "@material-ui/icons/InfoOutlined";

/** A surface to show request status and information */
export interface StatusChipProps {
  /** The data attribute used for targeting this component in Cypress */
  "data-cy"?: string;
  title?: string;
  message?: string | React.ReactNode;
  type: "loading" | "error" | "success" | "info";
  retry?: () => void;
  // TODO: Get rid of link. It's used for one component + inappropriate:
  //  src/products/sales/src/containers/StepChoose/wlrBroadband/Resigns/ResignProductSelector.js
  link?: React.ReactNode;
  style?: React.CSSProperties;
  hasMarginBottom?: boolean;
}

export const StatusChip = ({
  title,
  message,
  type = "info",
  retry,
  link,
  style,
  "data-cy": dataCy = "StatusChip",
  hasMarginBottom = false
}: StatusChipProps) => {
  const useStyles = makeStyles(theme => ({
    root: {
      display: "flex",
      alignItems: "center",
      borderRadius: 4,
      color:
        type === "loading"
          ? theme.palette.primary.dark
          : theme.palette[type].contrastText,
      background:
        type === "loading"
          ? theme.palette.grey["200"]
          : theme.palette[type].main,
      padding: theme.spacing(1, 1),
      "& a": {
        color: "inherit",
        textDecoration: "underline"
      },
      ...(hasMarginBottom && { marginBottom: 15 })
    },
    icon: {},
    message: {
      paddingLeft: `${theme.spacing(1)}px`
    },
    title: {
      fontSize: 16,
      fontWeight: "bold",
      marginBottom: 5
    },
    retry: {
      marginLeft: 10,
      color: "inherit",
      fontWeight: "bold",
      textDecoration: "underline",
      cursor: "pointer"
    }
  }));

  const classes = useStyles();

  if (message || title) {
    if (typeof message !== "string" && !React.isValidElement(message)) {
      // Don't blow up if a JS object has been passed by mistake. eg. a weird DC error response.
      message = JSON.stringify(message);
    }
    return (
      <div className={classes.root} data-cy={dataCy} style={style}>
        <div className={classes.icon}>
          {type === "loading" && <CircularProgress color="inherit" />}
          {type === "error" && (
            <ErrorIcon style={{ width: 30, height: 30 }} color="inherit" />
          )}
          {type === "success" && (
            <CheckIcon style={{ width: 30, height: 30 }} color="inherit" />
          )}
          {type === "info" && (
            <InfoIcon style={{ width: 30, height: 30 }} color="inherit" />
          )}
        </div>
        <div className={classes.message}>
          {title && <div className={classes.title}>{title}</div>}
          {message}
          {retry && (
            <span className={classes.retry} onClick={retry}>
              Please try again
            </span>
          )}
          {link && link}
        </div>
      </div>
    );
  }
  return <div />;
};

StatusChip.displayName = "StatusChip";
