import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { SearchField } from "../components/SearchField";

const useStyles = makeStyles(theme => ({
  box: {
    minWidth: 340,
    marginLeft: 300,
    backgroundColor: theme.palette.grey[100],
    borderRadius: 5
  }
}));

export default function GlobalSearchToolbar() {
  const history = useHistory();
  const classes = useStyles();
  const [query] = useQueryParam("query", withDefault(StringParam, ""));
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setSearchQuery(query);
  }, [query, setSearchQuery]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    history.push("/search?query=" + searchQuery);
    setSearchQuery("");
  };

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit}
      style={{ marginRight: 10 }}
    >
      <Box className={classes.box}>
        <SearchField
          onClick={handleSubmit}
          value={searchQuery}
          margin="dense"
          onChange={e => setSearchQuery(e.target.value)}
        />
      </Box>
    </form>
  );
}
