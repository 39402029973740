import React from "react";
import { useAuth } from "@akj-dev/affinity-platform";
import { Button } from "@material-ui/core";

export const Logout = () => {
  const { logout } = useAuth();
  return (
    <Button onClick={logout} variant="outlined" size="small" color="inherit">
      Logout
    </Button>
  );
};
