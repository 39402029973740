import {
  affinityTheme,
  vodaTheme,
  daisyTheme,
  dwpTheme
} from "@akj-dev/design-system";

import { AppTheme } from "./theme";
import { hostToBrand } from "./hostToBrand";

export function activeThemeBrand() {
  return hostToBrand(window.location.hostname);
}

export function getTheme() {
  switch (activeThemeBrand()) {
    case AppTheme.VODAFONE:
      return vodaTheme;
    case AppTheme.DAISY:
      return daisyTheme;
    case AppTheme.DWS:
      return dwpTheme;
    case AppTheme.AFFINITY:
    default:
      return affinityTheme;
  }
}
