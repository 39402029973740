import styled from "styled-components";

export const ProductContainer = styled.div`
  display: flex;
  padding-top: 65px;
  padding-left: 56px;
  height: 100vh;
  background: ${p => p.theme.palette.common.white};
`;

export const ViewContainer = styled.div`
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;

// See <UATBanner /> in AppUnified.tsx
const showUatBanner =
  process.env.REACT_APP_STAGE === "uat" ||
  process.env.REACT_APP_STAGE === "development";

export const ContentContainer = styled.div`
  padding: 0 32px ${showUatBanner ? 100 : 16}px 32px;
  max-width: 1600px;
`;

export const PlaceholderImage = styled.img`
  margin-bottom: 2rem;
  min-width: 800px;
`;
